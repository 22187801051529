import {
  Box,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  Button,
  Notification,
  CustomModalLayout,
  Modal
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import BG from "./pdfbg.png";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
import i18n from './i18n';
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Everything in Pro, plus:",
  "Customizable Features & Wordings",
  "Access to widget API (Velo)",
  "Priority Support",
];

const INTERVAL = 4000;

function App() {

  const { t } =useTranslation();
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [InteractiveDemoOpen, setInteractiveDemoOpen] =
  React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/pdf-viewer/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const isAppUpgraded = data.instance.isFree === false;
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(isAppUpgraded);
        setIsUpgradeBannerOpen(!isAppUpgraded);
      })
      .catch(() => {});
  }

  const planId = (instanceData as any)?.instance?.availablePlans[0]?.name;

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=3495efc2-56a9-41e9-bb3c-8c9b3b3ab501&redirectUrl=https://certifiedcode.wixsite.com/pdf-viewer/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"3495efc2-56a9-41e9-bb3c-8c9b3b3ab501"}?appInstanceId=${
    (instanceData as any)["instance"]["instanceId"]
  }`;
  const openInteractiveDemo = () => {
    setInteractiveDemoOpen(true);
  };
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
        {t('upgradeMessage', { content: t(`content.${index}`) })}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Modal
            isOpen={InteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setInteractiveDemoOpen(false)}
          >
             <CustomModalLayout
              removeContentPadding={true}
              content={
                <div style={{ position: "relative", paddingBottom: "calc(56.22% + 41px)", height: 0, width: "100%" }}>
                  <iframe
                    src="https://app.arcade.software/share/tIaDeDyHTkUAIsuGodP7?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
                    title="Get Paid with PDF Viewer Dashboard | Getting Started"
                    frameBorder="0"
                    loading="lazy"
                    allow="clipboard-write"
                    allowFullScreen
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
                  ></iframe>
                </div>
              }
            />
          </Modal>

      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="440px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('pdf-title')}
                content={t('pdf-content')}
                actions={
                  // <Box direction="vertical" gap="SP2">
                  //   <Button
                  //     skin="premium"
                  //     prefixIcon={<Icons.PremiumFilled />}
                  //     onClick={() => {
                  //       window.open(upgradeUrl);
                  //     }}
                  //   >
                  //     {isUpgraded ? t('pdf-manage-sub') : t('pdf-upgrade-button')}
                  //   </Button>
                  //   <Button
                  //     skin="inverted"
                  //     as="a"
                  //     href="https://bridget.reviews.certifiedcode.us/3495efc2-56a9-41e9-bb3c-8c9b3b3ab501"
                  //     target="_blank"
                  //     >
                  //     {t('pdf-rate-divider-button')}
                  //   </Button>
                  //   {/* <Button
                  //     prefixIcon={<Icons.GetStarted />}
                  //     as="a"
                  //     href="https://support.certifiedcode.us/en/articles/8678985-track-setup-guide"
                  //     target="_blank"
                  //   >
                  //     Setup Guide
                  //   </Button> */}
                  // </Box>
                  <Box direction="horizontal" gap="SP2">
  <Button
    skin="premium"
    prefixIcon={<Icons.PremiumFilled />}
    onClick={() => {
      window.open(upgradeUrl);
    }}
  >
    {isUpgraded ? t('pdf-manage-sub') : t('pdf-upgrade-button')}
  </Button>
  <Button
    skin="inverted"
    as="a"
    href="https://bridget.reviews.certifiedcode.us/3495efc2-56a9-41e9-bb3c-8c9b3b3ab501"
    target="_blank"
  >
    {t('pdf-rate-divider-button')}
  </Button>
  {/* <Button
    prefixIcon={<Icons.GetStarted />}
    as="a"
    href="https://support.certifiedcode.us/en/articles/8678985-track-setup-guide"
    target="_blank"
  >
    Setup Guide
  </Button> */}
</Box>

                }
              />
             
            </Box>
          }
          image={<Image transparent borderRadius={0} src={BG} />}
        />
      </Card>
      <Card>
              <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                content={
                  <Box  marginTop= "-260px" verticalAlign="middle">
                    <MarketingPageLayoutContent
                      size="medium"
                      title="Learn how to setup PDF Viewer Dashboard"
                      content="Estimated time: 5 minutes"
                      actions={
                        <Box marginTop="-30px">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={openInteractiveDemo}
                        >
                          Watch video
                        </Button>
                      </Box>
                      }
                    />
                  </Box>
                }
              />
         </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
